import axios from 'axios';

const STAGE = process.env.REACT_APP_STAGE;

export function isProd() {
  return STAGE === 'prod';
}

export function getApplicationById(id) {
  const url = getServiceUrl(`/v1/prescreen/application/${id}`);

  return (
    axios
      .get(url)
      // axios data
      .then((response) => response?.data)
      // internal service data
      .then((response) => response?.data)
      .catch((error) => {
        return Promise.reject(error);
      })
  );
}

export function saveApplication(application) {
  const { id, ...otherAttrs } = application;
  const url = getServiceUrl(`/v1/prescreen/application/${id}`);

  return (
    axios
      .post(url, otherAttrs)
      // axios data
      .then((response) => response?.data)
      .catch((error) => {
        return Promise.reject(error);
      })
  );
}

export function getDownloadUrl(id) {
  const url = getServiceUrl(`/v1/prescreen/pdf/generate/${id}`);

  return (
    axios
      .get(url)
      // axios data
      .then((response) => response?.data)
      .then((response) => {
        return response?.data?.url;
      })
      .catch((error) => {
        return Promise.reject(error);
      })
  );
}

export function getServiceUrl(path) {
  switch (STAGE) {
    case 'local':
      return `http://localhost:3100/dev${path}`;
    case 'dev':
      return `https://k7qsoz04gk.execute-api.us-east-1.amazonaws.com/dev${path}`;
    case 'prod':
      return `https://1c7p27s00e.execute-api.us-east-1.amazonaws.com/prod${path}`;
    default:
      throw new Error(`Undefined stage ${STAGE}`);
  }
}
