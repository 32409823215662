import React from 'react';
import { MdAdd, MdClear } from 'react-icons/md';
import { MoneyInput } from '../MoneyInput';
import { useMount } from '../../hooks';
import { initializeObjectWithId } from '../../utils';

export function DscrTab({
  Dscr,
  addNewItem,
  removeItem,
  updateApplicationByPath,
  updateObjectInArray,
}) {
  const items = Dscr?.Items || [];
  const addButtonEnabled = items?.length < 4;
  const nextYear = Number(items[items?.length - 1]?.Year) - 1;

  useMount(() => {
    if (!items?.length) {
      updateApplicationByPath('Dscr.Items', [
        initializeObjectWithId({ Year: '2020' }),
        initializeObjectWithId({ Year: '2019' }),
      ]);
    }
  });

  return (
    <>
      <div className="dscr-table">
        <div className="dscr-table__row dscr-table__row--header">
          <div className="dscr-table__col dscr-table__col--label">
            {addButtonEnabled && (
              <button
                className="btn btn-sm btn-icon-add"
                type="button"
                onClick={() => addNewItem('Dscr.Items', { Year: nextYear })}
              >
                <MdAdd style={{ marginRight: '3px' }} /> {nextYear}
              </button>
            )}
          </div>

          {items?.map((item, index) => {
            return (
              <div key={item?.id} className="dscr-table__col dscr-table__col--year">
                {index === 0 ? `${item?.Year} Interim` : item?.Year}
                {index > 1 && index + 1 === items?.length && (
                  <button
                    className="btn btn-delete-item ml--sm"
                    tabIndex={index + 1}
                    style={{ width: '16px', height: '16px' }}
                    type="button"
                    onClick={() => removeItem('Dscr.Items', item?.id)}
                  >
                    <MdClear />
                  </button>
                )}
              </div>
            );
          })}
        </div>

        <div className="dscr-table__row">
          <div className="dscr-table__col dscr-table__col--label">Net Income</div>
          {items?.map((item, index) => {
            return (
              <div key={item?.id} className="dscr-table__col">
                <MoneyInput
                  className="w100"
                  tabIndex={index + 1}
                  value={item?.NetIncome}
                  onValueChange={(values) => {
                    updateObjectInArray('Dscr.Items', item?.id, 'NetIncome', values.value);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="dscr-table__row">
          <div className="dscr-table__col dscr-table__col--label">Interest</div>
          {items?.map((item, index) => {
            return (
              <div key={item?.id} className="dscr-table__col">
                <MoneyInput
                  className="w100"
                  tabIndex={index + 1}
                  value={item?.Interest}
                  onValueChange={(values) => {
                    updateObjectInArray('Dscr.Items', item?.id, 'Interest', values.value);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="dscr-table__row">
          <div className="dscr-table__col dscr-table__col--label">Depreciation Items</div>
          {items?.map((item, index) => {
            return (
              <div key={item?.id} className="dscr-table__col">
                <MoneyInput
                  className="w100"
                  tabIndex={index + 1}
                  value={item?.Depreciation}
                  onValueChange={(values) => {
                    updateObjectInArray('Dscr.Items', item?.id, 'Depreciation', values.value);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="dscr-table__row">
          <div className="dscr-table__col dscr-table__col--label">Deductions</div>
          {items?.map((item, index) => {
            return (
              <div key={item?.id} className="dscr-table__col">
                <MoneyInput
                  className="w100"
                  tabIndex={index + 1}
                  value={item?.Deductions}
                  onValueChange={(values) =>
                    updateObjectInArray('Dscr.Items', item?.id, 'Deductions', values.value)
                  }
                />
              </div>
            );
          })}
        </div>
        <div className="dscr-table__row dscr-table__row--highlight mt--sm">
          <div className="dscr-table__col dscr-table__col--label dscr-formula">
            EBITDA
            <br />
            (Net Income + Interest + Depreciation Items - Deductions)
          </div>
          {items?.map((item) => {
            return (
              <div key={item?.id} className="dscr-table__col">
                <MoneyInput
                  className="backgroundless borderless w100 font-semibold"
                  readOnly
                  tabIndex="-1"
                  fixedDecimalScale
                  decimalScale={2}
                  value={calculateEbitdar(item)}
                />
              </div>
            );
          })}
        </div>

        <div className="dscr-table__row mt">
          <div className="dscr-table__col dscr-table__col--label">Existing Debt</div>
          {items?.map((item, index) => {
            return (
              <div key={item?.id} className="dscr-table__col">
                <MoneyInput
                  className="w100"
                  tabIndex={index + 1}
                  value={item?.ExistingDebt}
                  onValueChange={(values) =>
                    updateObjectInArray('Dscr.Items', item?.id, 'ExistingDebt', values.value)
                  }
                />
              </div>
            );
          })}
        </div>
        <div className="dscr-table__row">
          <div className="dscr-table__col dscr-table__col--label">Proposed Debt</div>
          {items?.map((item, index) => {
            return (
              <div key={item?.id} className="dscr-table__col">
                <MoneyInput
                  className="w100"
                  tabIndex={index + 1}
                  value={item?.ProposedDebt}
                  onValueChange={(values) =>
                    updateObjectInArray('Dscr.Items', item?.id, 'ProposedDebt', values.value)
                  }
                />
              </div>
            );
          })}
        </div>
        <div className="dscr-table__row dscr-table__row--highlight mt--sm">
          <div className="dscr-table__col dscr-table__col--label dscr-formula">
            Total Debt
            <br />
            (Existing Debt + Proposed Debt)
          </div>
          {items?.map((item) => {
            return (
              <div key={item?.id} className="dscr-table__col">
                <MoneyInput
                  className="backgroundless borderless w100 font-semibold"
                  readOnly
                  tabIndex="-1"
                  fixedDecimalScale
                  decimalScale={2}
                  value={calculateTotalDebt(item)}
                />
              </div>
            );
          })}
        </div>

        <div className="dscr-table__row dscr-table__row--highlight mt--lg">
          <div className="dscr-table__col dscr-table__col--label dscr-formula dscr-formula--main">
            DSCR
            <br />
            (EBITDA / Total Debt)
          </div>
          {items?.map((item) => {
            return (
              <div key={item?.id} className="dscr-table__col">
                <MoneyInput
                  className="backgroundless borderless w100 text-center font-semibold"
                  readOnly
                  tabIndex="-1"
                  fixedDecimalScale
                  prefix=""
                  decimalScale={2}
                  value={calculateDscr(item)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

function calculateEbitdar(item) {
  return sum([item?.NetIncome, item?.Interest, item?.Depreciation, -item?.Deductions]);
}

function calculateTotalDebt(item) {
  return sum([item?.ExistingDebt, item?.ProposedDebt]);
}

function calculateDscr(item) {
  const ebitdar = calculateEbitdar(item);
  const totalDebt = calculateTotalDebt(item);

  if (ebitdar && totalDebt) {
    return ebitdar / totalDebt;
  }

  return 0;
}

function sum(numbers) {
  return numbers
    .filter((n) => !isNaN(n))
    .map((n) => Number(n))
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
}
