import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Input } from '../Input';
import { MoneyInput } from '../MoneyInput';

export function UseOfFundsTab({
  UseOfFunds,
  addNewItem,
  removeItem,
  updateObjectInArray,
  updateApplicationByPath,
}) {
  return (
    <>
      <div className="mb--lg">
        {UseOfFunds?.Items?.map((useOfFund) => {
          const { id } = useOfFund;

          return (
            <div className="position-relative mb" key={id}>
              <div className="mb--sm form-group-inline">
                <label htmlFor={`use-of-funds-amount-${id}`}>Amount</label>
                <MoneyInput
                  id={`use-of-funds-amount-${id}`}
                  value={useOfFund.Amount}
                  onValueChange={(values) =>
                    updateObjectInArray('UseOfFunds.Items', id, 'Amount', values.value)
                  }
                />
              </div>

              <div className="mb--sm form-group-inline">
                <label htmlFor={`use-of-funds-description-${id}`}>Description</label>
                <Input
                  id={`use-of-funds-description-${id}`}
                  className="input-longer"
                  type="text"
                  value={useOfFund.Description}
                  onChange={(event) =>
                    updateObjectInArray('UseOfFunds.Items', id, 'Description', event.target.value)
                  }
                />
              </div>

              <button
                className="position-absolute top-right btn btn-delete-item"
                type="button"
                onClick={() => removeItem('UseOfFunds.Items', id)}
              >
                <MdDelete size="24px" />
              </button>
            </div>
          );
        })}

        <button onClick={() => addNewItem('UseOfFunds.Items')} type="button">
          Add Use Of Funds
        </button>
      </div>

      <div className="mb--sm form-group-inline">
        <label htmlFor="use-of-funds-number-of-jobs-created">Number Of Jobs Created</label>
        <Input
          id="use-of-funds-number-of-jobs-created"
          type="number"
          min="0"
          step="1"
          value={UseOfFunds?.NumberOfJobsCreated}
          onChange={(event) =>
            updateApplicationByPath('UseOfFunds.NumberOfJobsCreated', event.target.value)
          }
        />
      </div>

      <div className="form-group-inline">
        <label htmlFor="use-of-funds-number-of-jobs-retained">Number Of Jobs Retained</label>
        <Input
          id="use-of-funds-number-of-jobs-retained"
          type="number"
          min="0"
          step="1"
          value={UseOfFunds?.NumberOfJobsRetained}
          onChange={(event) =>
            updateApplicationByPath('UseOfFunds.NumberOfJobsRetained', event.target.value)
          }
        />
      </div>
    </>
  );
}
