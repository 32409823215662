export function getIsoString() {
  return new Date().toISOString();
}

export function getTimestamp() {
  return Math.floor(Date.now() / 1000);
}

export function timestampToLocalDate(timestamp) {
  if (!timestamp) {
    return '';
  }

  return new Date(timestamp * 1000).toLocaleString();
}

export function initializeObjectWithId({ ...attrs } = {}) {
  return {
    id: uuidv4(),
    ...attrs,
  };
}

export function hasElements(array) {
  return !!array?.length;
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
