import React, { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { Input } from '../Input';
import { OBJECT_TYPES } from '../../constants';
import { getTimestamp, timestampToLocalDate } from '../../utils';
import { Fieldset } from '../Fieldset';

export function ObjectsTab({
  SortedObjects,
  addNewItem,
  removeItem,
  updateObjectInArray,
  addWarningNotification,
}) {
  const [tmpObject, updateTmpObject] = useState({});

  return (
    <>
      <div className="mb--lg">
        <div className="display-flex align-items-center justify-content-center mb--sm">
          <select
            value={tmpObject?.ObjectType || ''}
            onChange={(event) => {
              const { value } = event.target;

              updateTmpObject((prevState) => ({
                ...prevState,
                ObjectType: value,
              }));
            }}
          >
            <option value="">Not Selected</option>
            <option value={OBJECT_TYPES.GUARANTOR}>Guarantor</option>
            <option value={OBJECT_TYPES.BORROWER}>Borrower</option>
            <option value={OBJECT_TYPES.AFFILIATE}>Affiliate</option>
          </select>

          <Input
            className="input-longer ml--sm"
            type="text"
            value={tmpObject?.Name}
            onChange={(event) => {
              const { value } = event.target;

              updateTmpObject((prevState) => ({
                ...prevState,
                Name: value,
              }));
            }}
          />

          <button
            className="btn btn-primary ml--sm"
            style={{ paddingTop: '0', paddingBottom: '0' }}
            type="button"
            onClick={() => {
              if (!tmpObject?.ObjectType) {
                return addWarningNotification('Please select object type');
              }

              if (!tmpObject?.Name) {
                return addWarningNotification('Please provide name');
              }

              addNewItem('Objects', { ...tmpObject, DateAdded: getTimestamp() });
              // lets refresh the form
              updateTmpObject({});
            }}
          >
            Add
          </button>
        </div>
      </div>

      {SortedObjects?.map((object) => {
        const { id, ObjectType, DateAdded, Name } = object;

        return (
          <Fieldset className="mb position-relative" key={id} id={id} legend={ObjectType}>
            <div className="form-group-inline mb--sm">
              <label htmlFor={`object-name-${id}`}>Name</label>
              <Input
                className="input-longer"
                id={`object-name-${id}`}
                value={Name}
                onChange={(event) => updateObjectInArray('Objects', id, 'Name', event.target.value)}
              />
            </div>

            <div className="form-group-inline">
              Date Added <strong>{timestampToLocalDate(DateAdded)}</strong>
            </div>

            <button
              className="btn btn-delete-item"
              type="button"
              onClick={() => removeItem('Objects', id)}
            >
              <MdDelete size="24px" />
            </button>
          </Fieldset>
        );
      })}
    </>
  );
}
