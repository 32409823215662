import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Input } from '../Input';
import { MoneyInput } from '../MoneyInput';

export function AltSizeStandardTab({
  AltSizeStandard,
  addNewItem,
  removeItem,
  updateObjectInArray,
}) {
  return (
    <>
      <table className="table table-collateral">
        <thead>
          <tr>
            <th>Primary Industry</th>
            <th>NAICS Code</th>
            <th>Tangible Net Worth</th>
            <th>
              Avg Net Income
              <br />
              After Federal Income Taxes
              <br />
              Past 2 yrs
            </th>
            <th>
              Tangible Net Worth {'<='} $15mm <br />
              AND
              <br />
              Avg Net Income <br />
              Past 2 yrs {'<='} $5mm?
            </th>
            <th className="table__col-delete borderless">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {AltSizeStandard?.Items?.map((item) => {
            const {
              id,
              PrimaryIndustry,
              NaicsCode,
              TangibleNetWorth,
              AvgNetIncomeAfterTaxes,
              TangibleNetWorthLess15,
            } = item;

            return (
              <tr key={id}>
                <td className="td--input">
                  <Input
                    type="text"
                    value={PrimaryIndustry}
                    onChange={(event) =>
                      updateObjectInArray(
                        'AltSizeStandard.Items',
                        id,
                        'PrimaryIndustry',
                        event.target.value
                      )
                    }
                  />
                </td>
                <td className="td--input">
                  <Input
                    type="number"
                    value={NaicsCode}
                    onChange={(event) =>
                      updateObjectInArray(
                        'AltSizeStandard.Items',
                        id,
                        'NaicsCode',
                        event.target.value
                      )
                    }
                  />
                </td>
                <td className="td--input">
                  <MoneyInput
                    value={TangibleNetWorth}
                    onValueChange={(values) =>
                      updateObjectInArray(
                        'AltSizeStandard.Items',
                        id,
                        'TangibleNetWorth',
                        values.value
                      )
                    }
                  />
                </td>
                <td className="td--input">
                  <MoneyInput
                    value={AvgNetIncomeAfterTaxes}
                    onValueChange={(values) =>
                      updateObjectInArray(
                        'AltSizeStandard.Items',
                        id,
                        'AvgNetIncomeAfterTaxes',
                        values.value
                      )
                    }
                  />
                </td>
                <td className="td--input text-center">
                  <Input
                    type="checkbox"
                    checked={!!TangibleNetWorthLess15}
                    onChange={(event) =>
                      updateObjectInArray(
                        'AltSizeStandard.Items',
                        id,
                        'TangibleNetWorthLess15',
                        event.target.checked
                      )
                    }
                  />
                </td>
                <td className="table__col-delete">
                  <button
                    className="btn btn-delete-item"
                    type="button"
                    onClick={() => removeItem('AltSizeStandard.Items', id)}
                  >
                    <MdDelete size="20px" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="text-center" colSpan="5">
              <button
                className="btn btn-primary btn-sm table_btn-add"
                type="button"
                onClick={() => addNewItem('AltSizeStandard.Items')}
              >
                Add
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}
