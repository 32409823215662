import React, { useReducer, useEffect } from 'react';
import Prescreen from './Prescreen';
import { Notification } from './components/Notification';
import { getApplicationById } from './service';
import { NOTIFICATION_TYPE } from './constants';

const ACTION = {
  REQUEST: 'request',
  SUCCESS: 'success',
  ERROR: 'error',
  MISSING_ID: 'missing-id',
  UPDATE_APP: 'update-app',
  NOTIFICATION_ADD: 'notification-add',
  NOTIFICATION_REMOVE: 'notification-remove',
};

const STATUS = {
  INITIAL: 'initial',
  REQUEST: 'request',
  SUCCESS: 'success',
  ERROR: 'error',
  MISSING_ID: 'missing-id',
};

const initialState = {
  status: STATUS.INITIAL,
  application: null,
  notification: null, // { message, type }
};

export default function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { status, application, notification } = state;

  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');

  useEffect(() => {
    if (!id) {
      dispatch({ type: ACTION.MISSING_ID });
    } else {
      dispatch({ type: ACTION.REQUEST });
      getApplicationById(id)
        .then((application) => {
          dispatch({ type: ACTION.SUCCESS, application });
        })
        .catch((error) => {
          console.error(error);
          dispatch({ type: ACTION.ERROR });
        });
    }
  }, [id]);

  const addNotification = ({ type, message }) =>
    dispatch({
      type: ACTION.NOTIFICATION_ADD,
      notification: { type, message },
    });

  const removeNotification = () => dispatch({ type: ACTION.NOTIFICATION_REMOVE });

  if (status === STATUS.INITIAL || status === STATUS.REQUEST) {
    return <div className="mt text-center">Preparing prescreen checklist...</div>;
  }

  if (status === STATUS.ERROR) {
    return <div className="mt text-center">Failed to load prescreen checklist</div>;
  }

  if (status === STATUS.MISSING_ID) {
    return <div className="mt text-center">Missing application id</div>;
  }

  return (
    <main className="container container--main">
      {notification ? (
        <Notification notification={notification} onClose={removeNotification} />
      ) : null}

      <Prescreen
        application={application}
        addSuccessNotification={(message) =>
          addNotification({ type: NOTIFICATION_TYPE.SUCCESS, message })
        }
        addWarningNotification={(message) =>
          addNotification({ type: NOTIFICATION_TYPE.WARNING, message })
        }
        addErrorNotification={(message) =>
          addNotification({ type: NOTIFICATION_TYPE.ERROR, message })
        }
        updateApplication={(application) => dispatch({ type: ACTION.UPDATE_APP, application })}
      />
    </main>
  );
}

function reducer(state, action) {
  switch (action.type) {
    case ACTION.REQUEST: {
      return { ...state, status: STATUS.REQUEST };
    }
    case ACTION.SUCCESS: {
      return { ...state, status: STATUS.SUCCESS, application: action.application };
    }
    case ACTION.ERROR: {
      return { ...state, status: STATUS.ERROR };
    }
    case ACTION.MISSING_ID: {
      return { ...state, status: STATUS.MISSING_ID };
    }
    case ACTION.UPDATE_APP: {
      return { ...state, application: action.application };
    }
    case ACTION.NOTIFICATION_ADD: {
      return { ...state, notification: action.notification };
    }
    case ACTION.NOTIFICATION_REMOVE: {
      return { ...state, notification: null };
    }
    default:
      return state;
  }
}
