import React from 'react';
import { Input } from '../Input';

export function IndustryCheckTab({ IndustryCheck, updateApplicationByPaths }) {
  return (
    <>
      <div className="mb--sm form-group-inline">
        <label htmlFor="industry-check-industry">Industry</label>
        <Input
          className="input-longer"
          id="industry-check-industry"
          type="text"
          value={IndustryCheck?.Name}
          onChange={(event) =>
            updateApplicationByPaths([{ path: 'IndustryCheck.Name', value: event.target.value }])
          }
        />
      </div>

      <div className="form-group-inline mb--sm">
        <label htmlFor="industry-check-naics-code">NAICS Code</label>
        <Input
          id="industry-check-naics-code"
          type="number"
          min="0"
          value={IndustryCheck?.Code}
          onChange={(event) => {
            const { value } = event.target;

            if (value?.length <= 6) {
              updateApplicationByPaths([{ path: 'IndustryCheck.Code', value }]);
            }
          }}
        />
      </div>

      <div className="form-group-inline mb--sm">
        <label>
          <Input
            type="checkbox"
            checked={!!IndustryCheck?.FranchiseBusiness}
            onChange={(event) => {
              const { checked } = event.target;

              if (checked) {
                updateApplicationByPaths([
                  { path: 'IndustryCheck.FranchiseBusiness', value: true },
                ]);
              } else {
                updateApplicationByPaths([
                  { path: 'IndustryCheck.FranchiseBusiness', value: false },
                  { path: 'IndustryCheck.FranchiseCode', value: '' },
                  { path: 'IndustryCheck.Eligible', value: false },
                  { path: 'IndustryCheck.MeetsFtc', value: false },
                  { path: 'IndustryCheck.Form2462Needed', value: false },
                ]);
              }
            }}
          />{' '}
          Is business a franchise?
        </label>
      </div>

      {!!IndustryCheck?.FranchiseBusiness && (
        <>
          <div className="form-group-inline mb--sm">
            <label htmlFor="sba-franchise-identifier-code">SBA Franchise Identifier Code</label>
            <Input
              id="sba-franchise-identifier-code"
              type="text"
              value={IndustryCheck?.FranchiseCode}
              onChange={(event) =>
                updateApplicationByPaths([
                  { path: 'IndustryCheck.FranchiseCode', value: event.target.value },
                ])
              }
            />
          </div>

          <div className="form-group-inline mb--sm">
            <label>
              <Input
                type="checkbox"
                checked={!!IndustryCheck?.Eligible}
                onChange={(event) =>
                  updateApplicationByPaths([
                    { path: 'IndustryCheck.Eligible', value: event.target.checked },
                  ])
                }
              />{' '}
              Industry Eligible per SBA?
            </label>
          </div>

          <div className="form-group-inline mb--sm">
            <label>
              <Input
                type="checkbox"
                checked={!!IndustryCheck?.MeetsFtc}
                onChange={(event) =>
                  updateApplicationByPaths([
                    { path: 'IndustryCheck.MeetsFtc', value: event.target.checked },
                  ])
                }
              />{' '}
              Meets FTC Franchise Definition?
            </label>
          </div>

          <div className="form-group-inline">
            <label>
              <Input
                type="checkbox"
                checked={!!IndustryCheck?.Form2462Needed}
                onChange={(event) =>
                  updateApplicationByPaths([
                    { path: 'IndustryCheck.Form2462Needed', value: event.target.checked },
                  ])
                }
              />{' '}
              SBA Form 2462 Needed?
            </label>
          </div>
        </>
      )}
    </>
  );
}
