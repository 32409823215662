import React from 'react';
import { MdDelete } from 'react-icons/md';
import produce from 'immer';
import set from 'lodash.set';
import { hasElements, initializeObjectWithId } from '../../utils';
import { Fieldset } from '../Fieldset';
import { Input } from '../Input';
import { MoneyInput } from '../MoneyInput';

export function TaxLienTab({ Guarantors, Borrowers, updateObjectInArray }) {
  return (
    <>
      {!hasElements([...Guarantors, ...Borrowers]) && (
        <div className="text-center">
          <strong>No guarantors/borrowers defined</strong>
        </div>
      )}

      {[...Guarantors, ...Borrowers].map((object) => {
        const { id, ObjectType, Name } = object;

        return (
          <Fieldset
            className="mb"
            key={id}
            id={id}
            legend={ObjectType}
            timestamp={object?.TaxLien?.Timestamp}
            onTimestampChange={(timestamp) =>
              updateObjectInArray('Objects', id, 'TaxLien.Timestamp', timestamp)
            }
          >
            <div className="mb--sm form-group-inline">
              <label htmlFor={`name-${id}`}>Name</label>
              <Input
                className="input-longer borderless backgroundless font-semibold"
                id={`name-${id}`}
                readOnly
                value={Name}
              />
            </div>

            {object?.TaxLien?.Items?.map((item) => {
              const updateTaxLienItem = (itemId, name, value) => {
                const newItems = produce(object?.TaxLien?.Items, (draftState) => {
                  const taxLien = draftState.find((i) => i.id === itemId);

                  set(taxLien, name, value);
                });

                updateObjectInArray('Objects', id, 'TaxLien.Items', newItems);
              };

              return (
                <div key={item?.id} className="position-relative mb">
                  <div className="mb--sm form-group-inline">
                    <label htmlFor={`amount-${item?.id}`}>Amount (total)</label>
                    <MoneyInput
                      id={`amount-${item?.id}`}
                      value={item?.AmountTotal}
                      onValueChange={(values) =>
                        updateTaxLienItem(item?.id, 'AmountTotal', values.value)
                      }
                    />
                  </div>

                  <div className="mb--sm form-group-inline">
                    <label htmlFor={`date-requested-${item?.id}`}>Date Identified</label>
                    <Input
                      id={`date-requested-${item?.id}`}
                      type="date"
                      value={item?.DateIdentified}
                      onChange={(event) =>
                        updateTaxLienItem(item?.id, 'DateIdentified', event.target.value)
                      }
                    />
                  </div>

                  <label className="display-block">
                    <Input
                      type="checkbox"
                      checked={!!item?.PaymentPlanRequested}
                      onChange={(event) =>
                        updateTaxLienItem(item?.id, 'PaymentPlanRequested', event.target.checked)
                      }
                    />{' '}
                    Payment Plan Requested
                  </label>

                  <button
                    className="position-absolute top-right btn btn-delete-item"
                    type="button"
                    onClick={() =>
                      updateObjectInArray(
                        'Objects',
                        id,
                        'TaxLien.Items',
                        object?.TaxLien?.Items?.filter((i) => i.id !== item.id)
                      )
                    }
                  >
                    <MdDelete size="24px" />
                  </button>
                </div>
              );
            })}

            <button
              className="mt--sm"
              type="button"
              onClick={() =>
                updateObjectInArray('Objects', id, 'TaxLien.Items', [
                  ...(object?.TaxLien?.Items || []),
                  initializeObjectWithId(),
                ])
              }
            >
              Add
            </button>
          </Fieldset>
        );
      })}
    </>
  );
}
