import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Input } from '../Input';
import { MoneyInput } from '../MoneyInput';

export function SizeStandardTab({ SizeStandard, addNewItem, removeItem, updateObjectInArray }) {
  return (
    <>
      <table className="table table-collateral">
        <thead>
          <tr>
            <th>Primary Industry</th>
            <th>NAICS Code</th>
            <th># of Employees</th>
            <th>
              Avg Annual
              <br />
              Receipts
            </th>
            <th>
              Size Standard
              <br />
              in Number of Employees
            </th>
            <th>
              Size Standard
              <br />
              in Millions of Dollars
            </th>
            <th>Is Small Business?</th>
            <th className="table__col-delete borderless">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {SizeStandard?.Items?.map((item) => {
            const {
              id,
              PrimaryIndustry,
              NaicsCode,
              AvgAnnualReceipts,
              SizeStandardEmployeesNumber,
              SizeStandardMillionsDollars,
              NumberOfEmployees,
              IsSmallBusiness,
            } = item;

            return (
              <tr key={id}>
                <td className="td--input">
                  <Input
                    type="text"
                    value={PrimaryIndustry}
                    onChange={(event) =>
                      updateObjectInArray(
                        'SizeStandard.Items',
                        id,
                        'PrimaryIndustry',
                        event.target.value
                      )
                    }
                  />
                </td>
                <td className="td--input">
                  <Input
                    type="number"
                    value={NaicsCode}
                    onChange={(event) =>
                      updateObjectInArray('SizeStandard.Items', id, 'NaicsCode', event.target.value)
                    }
                  />
                </td>
                <td className="td--input">
                  <Input
                    type="number"
                    min="0"
                    step="1"
                    value={NumberOfEmployees}
                    onChange={(event) =>
                      updateObjectInArray(
                        'SizeStandard.Items',
                        id,
                        'NumberOfEmployees',
                        event.target.value
                      )
                    }
                  />
                </td>
                <td className="td--input">
                  <MoneyInput
                    value={AvgAnnualReceipts}
                    onValueChange={(values) =>
                      updateObjectInArray(
                        'SizeStandard.Items',
                        id,
                        'AvgAnnualReceipts',
                        values.value
                      )
                    }
                  />
                </td>
                <td className="td--input">
                  <Input
                    type="number"
                    min="0"
                    step="1"
                    value={SizeStandardEmployeesNumber}
                    onChange={(event) =>
                      updateObjectInArray(
                        'SizeStandard.Items',
                        id,
                        'SizeStandardEmployeesNumber',
                        event.target.value
                      )
                    }
                  />
                </td>
                <td className="td--input">
                  <Input
                    type="number"
                    min="0"
                    step="0.1"
                    value={SizeStandardMillionsDollars}
                    onChange={(event) =>
                      updateObjectInArray(
                        'SizeStandard.Items',
                        id,
                        'SizeStandardMillionsDollars',
                        event.target.value
                      )
                    }
                  />
                </td>
                <td className="td--input text-center">
                  <Input
                    type="checkbox"
                    checked={!!IsSmallBusiness}
                    onChange={(event) =>
                      updateObjectInArray(
                        'SizeStandard.Items',
                        id,
                        'IsSmallBusiness',
                        event.target.checked
                      )
                    }
                  />
                </td>
                <td className="table__col-delete">
                  <button
                    className="btn btn-delete-item"
                    type="button"
                    onClick={() => removeItem('SizeStandard.Items', id)}
                  >
                    <MdDelete size="20px" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="text-center" colSpan="7">
              <button
                className="btn btn-primary btn-sm table_btn-add"
                type="button"
                onClick={() => addNewItem('SizeStandard.Items')}
              >
                Add
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}
