import React from 'react';
import classNames from 'classnames';
import { TimestampFooter } from '../TimestampFooter';
import './styles.css';

export function Section({ className, children, timestamp, onTimestampChange }) {
  const hasTimestampBtn = !!onTimestampChange;

  return (
    <section className={classNames(className, 'section')}>
      {children}

      {hasTimestampBtn && <TimestampFooter timestamp={timestamp} onChange={onTimestampChange} />}
    </section>
  );
}
