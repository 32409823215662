import React from 'react';
import { Input } from '../Input';

export function BusinessDescriptionTab({ BusinessDescription, updateApplicationByPath }) {
  return (
    <>
      <div className="mb--sm form-group-inline">
        <label htmlFor="filing-date">Started Date</label>
        <Input
          id="filing-date"
          type="date"
          value={BusinessDescription?.StartedDate}
          onChange={(event) =>
            updateApplicationByPath('BusinessDescription.StartedDate', event.target.value)
          }
        />
      </div>

      <div className="mb--sm form-group-inline">
        <label htmlFor="number-of-employees">Number of Employees</label>
        <Input
          id="number-of-employees"
          type="number"
          min="0"
          step="1"
          value={BusinessDescription?.NumberOfEmployees}
          onChange={(event) =>
            updateApplicationByPath('BusinessDescription.NumberOfEmployees', event.target.value)
          }
        />
      </div>

      <div className="form-group-inline">
        <label>
          <Input
            type="checkbox"
            checked={!!BusinessDescription?.UsaBased}
            onChange={(event) =>
              updateApplicationByPath('BusinessDescription.UsaBased', event.target.checked)
            }
          />{' '}
          US Based Business?
        </label>
      </div>

      <div className="mb--sm form-group-inline">
        <label htmlFor="business-address">Address</label>
        <textarea
          className="display-block w100"
          id="business-address"
          rows={4}
          value={BusinessDescription?.Address}
          onChange={(event) =>
            updateApplicationByPath('BusinessDescription.Address', event.target.value)
          }
        ></textarea>
      </div>

      <div className="mb--sm">
        <label htmlFor="guarantor-involvement-in-business">Guarantor Involvement in Business</label>
        <textarea
          className="display-block w100"
          id="guarantor-involvement-in-business"
          rows={4}
          value={BusinessDescription?.GuarantorInvolvementInBusiness}
          onChange={(event) =>
            updateApplicationByPath(
              'BusinessDescription.GuarantorInvolvementInBusiness',
              event.target.value
            )
          }
        ></textarea>
      </div>

      <div className="mb--sm">
        <label htmlFor="business-revenue-model">Business Revenue Model</label>
        <textarea
          className="display-block w100"
          id="business-revenue-model"
          rows={4}
          value={BusinessDescription?.BusinessRevenueModel}
          onChange={(event) =>
            updateApplicationByPath('BusinessDescription.BusinessRevenueModel', event.target.value)
          }
        ></textarea>
      </div>

      <div>
        <label htmlFor="history-of-business">History of Business</label>
        <textarea
          className="display-block w100"
          id="history-of-business"
          rows={4}
          value={BusinessDescription?.HistoryOfBusiness}
          onChange={(event) =>
            updateApplicationByPath('BusinessDescription.HistoryOfBusiness', event.target.value)
          }
        ></textarea>
      </div>
    </>
  );
}
