import React from 'react';
import classNames from 'classnames';
import { TimestampFooter } from '../TimestampFooter';
import './styles.css';

export function Fieldset({
  className,
  id,
  legend,
  children,
  hasComments = false,
  timestamp,
  comments,
  onCommentsChange,
  onTimestampChange,
}) {
  const hasTimestampBtn = !!onTimestampChange;

  return (
    <fieldset className={classNames(className, 'fieldset')}>
      {legend ? <legend>{legend}</legend> : null}

      {children}

      {hasComments && (
        <div className="mt--sm">
          <label htmlFor={`comment-${id}`}>Comments</label>
          <textarea
            className="display-block w100"
            id={`comment-${id}`}
            rows={3}
            value={comments}
            onChange={(event) => onCommentsChange(event.target.value)}
          ></textarea>
        </div>
      )}

      {hasTimestampBtn && <TimestampFooter timestamp={timestamp} onChange={onTimestampChange} />}
    </fieldset>
  );
}
