import React from 'react';
import { hasElements } from '../../utils';
import { Input } from '../Input';
import { Fieldset } from '../Fieldset';

export function SamTab({ Guarantors, Borrowers, updateObjectInArray }) {
  return (
    <>
      {!hasElements([...Guarantors, ...Borrowers]) && (
        <div className="text-center">
          <strong>No guarantors/borrowers defined</strong>
        </div>
      )}

      {[...Guarantors, ...Borrowers].map((object) => {
        const { id, ObjectType, Name } = object;

        return (
          <Fieldset
            className="mb"
            key={id}
            id={id}
            legend={ObjectType}
            hasComments={true}
            timestamp={object?.Sam?.Timestamp}
            comments={object?.Sam?.Comments}
            onCommentsChange={(value) => updateObjectInArray('Objects', id, 'Sam.Comments', value)}
            onTimestampChange={(value) =>
              updateObjectInArray('Objects', id, 'Sam.Timestamp', value)
            }
          >
            <div className="mb--sm form-group-inline">
              <label htmlFor={`name-${id}`}>Name</label>
              <Input
                className="input-longer borderless backgroundless font-semibold"
                id={`name-${id}`}
                readOnly
                value={Name}
              />
            </div>

            <div className="form-group-inline">
              <label>
                <Input
                  type="checkbox"
                  checked={!!object?.Sam?.NotFound}
                  onChange={(event) =>
                    updateObjectInArray('Objects', id, 'Sam.NotFound', event.target.checked)
                  }
                />{' '}
                No Cases Found in SAM
              </label>
            </div>
          </Fieldset>
        );
      })}
    </>
  );
}
