import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Input } from '../Input';
import { MoneyInput } from '../MoneyInput';

export function CollateralTab({ Collateral, addNewItem, removeItem, updateObjectInArray }) {
  return (
    <>
      <table className="table table-collateral">
        <thead>
          <tr>
            <th>Collateral Type</th>
            <th>
              Address/Serial Number/
              <br />
              VIN Number
            </th>
            <th>Current Loan Balance</th>
            <th>Original Loan Amount</th>
            <th>Current Value</th>
            <th className="table__col-delete borderless">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {Collateral?.Items?.map((item) => {
            const { id, Type, Number, CurrentLoanBalance, OriginalLoanAmount, CurrentValue } = item;

            return (
              <tr key={id}>
                <td className="td--input">
                  <Input
                    type="text"
                    value={Type}
                    onChange={(event) =>
                      updateObjectInArray('Collateral.Items', id, 'Type', event.target.value)
                    }
                  />
                </td>
                <td className="td--input">
                  <Input
                    type="text"
                    value={Number}
                    onChange={(event) =>
                      updateObjectInArray('Collateral.Items', id, 'Number', event.target.value)
                    }
                  />
                </td>
                <td className="td--input">
                  <MoneyInput
                    value={CurrentLoanBalance}
                    onValueChange={(values) =>
                      updateObjectInArray(
                        'Collateral.Items',
                        id,
                        'CurrentLoanBalance',
                        values.value
                      )
                    }
                  />
                </td>
                <td className="td--input">
                  <MoneyInput
                    value={OriginalLoanAmount}
                    onValueChange={(values) =>
                      updateObjectInArray(
                        'Collateral.Items',
                        id,
                        'OriginalLoanAmount',
                        values.value
                      )
                    }
                  />
                </td>
                <td className="td--input">
                  <MoneyInput
                    value={CurrentValue}
                    onValueChange={(values) =>
                      updateObjectInArray('Collateral.Items', id, 'CurrentValue', values.value)
                    }
                  />
                </td>
                <td className="table__col-delete">
                  <button
                    className="btn btn-delete-item"
                    type="button"
                    onClick={() => removeItem('Collateral.Items', id)}
                  >
                    <MdDelete size="20px" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="text-center" colSpan="5">
              <button
                className="btn btn-primary btn-sm table_btn-add"
                type="button"
                onClick={() => addNewItem('Collateral.Items')}
              >
                Add
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}
