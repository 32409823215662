import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Input } from '../Input';
import { MoneyInput } from '../MoneyInput';

export function OtherSbaLoansTab({ OtherSbaLoans, addNewItem, removeItem, updateObjectInArray }) {
  return (
    <>
      <div className="mb--lg">
        {OtherSbaLoans?.Items?.map((otherLoan) => {
          const { id } = otherLoan;

          return (
            <div key={id} className="position-relative mb">
              <div className="mb--sm form-group-inline">
                <label htmlFor={`other-loans-type-${id}`}>Loan Type</label>
                <select
                  id={`other-loans-amount-${id}`}
                  value={otherLoan.Type}
                  onChange={(event) =>
                    updateObjectInArray('OtherSbaLoans.Items', id, 'Type', event.target.value)
                  }
                >
                  <option value="">Not Selected</option>
                  <option value="EIDL">EIDL</option>
                  <option value="PPP">PPP</option>
                  <option value="SBA">SBA</option>
                </select>
              </div>

              <div className="mb--sm form-group-inline">
                <label htmlFor={`other-loans-amount-${id}`}>Loan Amount</label>
                <MoneyInput
                  id={`other-loans-amount-${id}`}
                  value={otherLoan.Amount}
                  onValueChange={(values) =>
                    updateObjectInArray('OtherSbaLoans.Items', id, 'Amount', values.value)
                  }
                />
              </div>

              <div className="mb--sm form-group-inline">
                <label htmlFor={`other-loans-date-${id}`}>Loan Date</label>
                <Input
                  id={`other-loans-date-${id}`}
                  type="date"
                  value={otherLoan.Date}
                  onChange={(event) =>
                    updateObjectInArray('OtherSbaLoans.Items', id, 'Date', event.target.value)
                  }
                />
              </div>

              <div>
                <label htmlFor={`other-loans-details-${id}`}>Detailed Use Of Loan</label>
                <textarea
                  className="display-block w100"
                  id={`other-loans-details-${id}`}
                  rows={4}
                  value={otherLoan.Details}
                  onChange={(event) =>
                    updateObjectInArray('OtherSbaLoans.Items', id, 'Details', event.target.value)
                  }
                ></textarea>
              </div>

              <button
                className="position-absolute top-right btn btn-delete-item"
                type="button"
                onClick={() => removeItem('OtherSbaLoans.Items', id)}
              >
                <MdDelete size="24px" />
              </button>
            </div>
          );
        })}

        <button onClick={() => addNewItem('OtherSbaLoans.Items')} type="button">
          Add Loan
        </button>
      </div>
    </>
  );
}
