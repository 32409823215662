import React from 'react';
import { Fieldset } from '../Fieldset';
import { Input } from '../Input';
import { hasElements } from '../../utils';
import { OBJECT_TYPES } from '../../constants';

export function ClearTab({
  Clear,
  Guarantors,
  Borrowers,
  updateApplicationByPath,
  updateObjectInArray,
}) {
  return (
    <>
      <div className="mb--lg">
        {!hasElements([...Guarantors, ...Borrowers]) && (
          <div className="text-center">
            <strong>No guarantors/borrowers defined</strong>
          </div>
        )}

        {[...Guarantors, ...Borrowers].map((object) => {
          const { id, ObjectType, Name } = object;
          const isGuarantor = ObjectType === OBJECT_TYPES.GUARANTOR;

          return (
            <Fieldset
              className="mb"
              key={id}
              id={id}
              legend={ObjectType}
              hasComments={true}
              timestamp={object?.Clear?.Timestamp}
              comments={object?.Clear?.Comments}
              onCommentsChange={(value) =>
                updateObjectInArray('Objects', id, 'Clear.Comments', value)
              }
              onTimestampChange={(value) =>
                updateObjectInArray('Objects', id, 'Clear.Timestamp', value)
              }
            >
              <div className="mb--sm form-group-inline">
                <label htmlFor={`name-${id}`}>Name</label>
                <Input
                  className="input-longer borderless backgroundless font-semibold"
                  id={`name-${id}`}
                  readOnly
                  value={Name}
                />
              </div>

              <div className="form-group-inline mb--sm">
                <label htmlFor={`date-report-obtained-${id}`}>Date Report Obtained</label>
                <Input
                  id={`date-report-obtained-${id}`}
                  type="date"
                  value={object?.Clear?.DateReportObtained}
                  onChange={(event) =>
                    updateObjectInArray(
                      'Objects',
                      id,
                      'Clear.DateReportObtained',
                      event.target.value
                    )
                  }
                />
              </div>

              {isGuarantor && (
                <label className="display-block mb--sm">
                  <Input
                    type="checkbox"
                    checked={!!object?.Clear?.NoFelonyCharges}
                    onChange={(event) =>
                      updateObjectInArray(
                        'Objects',
                        id,
                        'Clear.NoFelonyCharges',
                        event.target.checked
                      )
                    }
                  />{' '}
                  No felony charges on personal background check for all guarantors (including
                  spouses).
                </label>
              )}

              <label className="display-block mb--sm">
                <Input
                  type="checkbox"
                  checked={!!object?.Clear?.NoActiveBankruptcy}
                  onChange={(event) =>
                    updateObjectInArray(
                      'Objects',
                      id,
                      'Clear.NoActiveBankruptcy',
                      event.target.checked
                    )
                  }
                />{' '}
                No active bankruptcy and no recent bankruptcy in the past 3 years for all
                guarantors.
              </label>

              <label className="display-block">
                <Input
                  type="checkbox"
                  checked={!!object?.Clear?.NoForeclosures}
                  onChange={(event) =>
                    updateObjectInArray('Objects', id, 'Clear.NoForeclosures', event.target.checked)
                  }
                />{' '}
                No foreclosures in the past 2 years for all guarantors.
              </label>
            </Fieldset>
          );
        })}
      </div>

      <label className="display-block mb--sm">
        <Input
          type="checkbox"
          checked={!!Clear?.NoFelonyCharges}
          onChange={(event) =>
            updateApplicationByPath('Clear.NoFelonyCharges', event.target.checked)
          }
        />{' '}
        No felony charges on personal background check for all guarantors (including spouses).
      </label>

      <label className="display-block mb--sm">
        <Input
          type="checkbox"
          checked={!!Clear?.NoActiveBankruptcy}
          onChange={(event) =>
            updateApplicationByPath('Clear.NoActiveBankruptcy', event.target.checked)
          }
        />{' '}
        No active bankruptcy and no recent bankruptcy in the past 3 years for all guarantors.
      </label>

      <label className="display-block mb--sm">
        <Input
          type="checkbox"
          checked={!!Clear?.NoForeclosures}
          onChange={(event) =>
            updateApplicationByPath('Clear.NoForeclosures', event.target.checked)
          }
        />{' '}
        No foreclosures in the past 2 years for all guarantors.
      </label>

      <div>
        <label htmlFor="clears-notes">Make note of any ACTIVE UCC’s</label>
        <textarea
          className="display-block w100"
          id="clears-notes"
          rows={4}
          value={Clear?.Notes}
          onChange={(event) => updateApplicationByPath('Clear.Notes', event.target.value)}
        ></textarea>
      </div>
    </>
  );
}
