import React from 'react';
import { MdDelete } from 'react-icons/md';
import { hasElements } from '../../utils';
import { Input } from '../Input';
import { MoneyInput } from '../MoneyInput';

export function AffiliatesTab({ Affiliates, removeItem, updateObjectInArray }) {
  return (
    <>
      <table className="table table-collateral">
        <thead>
          <tr>
            <th>Business Name</th>
            <th>Ownership Percentage</th>
            <th>Industry Type</th>
            <th>Gross Annual Revenue</th>
            <th>Number of Employees</th>
            <th className="table__col-delete borderless">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {Affiliates?.map((item) => {
            const {
              id,
              Name,
              Ownership,
              IndustryType,
              GrossAnnualRevenue,
              NumberOfEmployees,
            } = item;

            return (
              <tr key={id}>
                <td className="td--input">
                  <Input
                    className="backgroundless font-semibold"
                    type="text"
                    value={Name}
                    readOnly
                  />
                </td>
                <td className="td--input">
                  <Input
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                    value={Ownership}
                    onChange={(event) =>
                      updateObjectInArray('Objects', id, 'Ownership', event.target.value)
                    }
                  />
                </td>
                <td className="td--input">
                  <Input
                    type="text"
                    value={IndustryType}
                    onChange={(event) =>
                      updateObjectInArray('Objects', id, 'IndustryType', event.target.value)
                    }
                  />
                </td>
                <td className="td--input">
                  <MoneyInput
                    value={GrossAnnualRevenue}
                    onValueChange={(values) =>
                      updateObjectInArray('Objects', id, 'GrossAnnualRevenue', values.value)
                    }
                  />
                </td>
                <td className="td--input">
                  <Input
                    type="number"
                    min="0"
                    step="1"
                    value={NumberOfEmployees}
                    onChange={(event) =>
                      updateObjectInArray('Objects', id, 'NumberOfEmployees', event.target.value)
                    }
                  />
                </td>
                <td className="table__col-delete">
                  <button
                    className="btn btn-delete-item"
                    type="button"
                    onClick={() => removeItem('Objects', id)}
                  >
                    <MdDelete size="20px" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {!hasElements(Affiliates) && (
        <div className="text-center mt">
          <strong>No affiliates defined</strong>
        </div>
      )}
    </>
  );
}
