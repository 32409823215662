import React from 'react';
import { hasElements } from '../../utils';
import { Fieldset } from '../Fieldset';
import { Input } from '../Input';

export function OccupationalCheckTab({ Guarantors, Borrowers, updateObjectInArray }) {
  return (
    <>
      {!hasElements([...Guarantors, ...Borrowers]) && (
        <div className="text-center">
          <strong>No guarantors/borrowers defined</strong>
        </div>
      )}

      {[...Guarantors, ...Borrowers].map((object) => {
        const { id, ObjectType, Name } = object;

        return (
          <Fieldset
            className="mb"
            key={id}
            id={id}
            legend={ObjectType}
            timestamp={object?.OccupationalCheck?.Timestamp}
            onTimestampChange={(timestamp) =>
              updateObjectInArray('Objects', id, 'OccupationalCheck.Timestamp', timestamp)
            }
          >
            <div className="mb--sm form-group-inline">
              <label htmlFor={`name-${id}`}>Name</label>
              <Input
                className="input-longer borderless backgroundless font-semibold"
                id={`name-${id}`}
                readOnly
                value={Name}
              />
            </div>

            <div className="form-group-inline mb--sm">
              <label>
                <Input
                  type="checkbox"
                  checked={!!object?.OccupationalCheck?.FoundAndAttached}
                  onChange={(event) =>
                    updateObjectInArray(
                      'Objects',
                      id,
                      'OccupationalCheck.FoundAndAttached',
                      event.target.checked
                    )
                  }
                />{' '}
                License Found and Attached
              </label>
            </div>

            <div className="form-group-inline">
              <label>
                <Input
                  id={`not-found-${id}`}
                  type="checkbox"
                  checked={!!object?.OccupationalCheck?.NotApplicable}
                  onChange={(event) => {
                    updateObjectInArray(
                      'Objects',
                      id,
                      'OccupationalCheck.NotApplicable',
                      event.target.checked
                    );
                  }}
                />{' '}
                Not Applicable
              </label>
            </div>
          </Fieldset>
        );
      })}
    </>
  );
}
