import React, { useState } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';
import { MdDone, MdPictureAsPdf } from 'react-icons/md';
import produce from 'immer';
import set from 'lodash.set';
import get from 'lodash.get';
import orderBy from 'lodash.orderby';
import { Section } from './components/Section';
import { Input } from './components/Input';
import { ObjectsTab } from './components/TabPanels/ObjectsTab';
import { BusinessDescriptionTab } from './components/TabPanels/BusinessDescriptionTab';
import { PersonalCreditTab } from './components/TabPanels/PersonalCreditTab';
import { TaxLienTab } from './components/TabPanels/TaxLienTab';
import { UseOfFundsTab } from './components/TabPanels/UseOfFundsTab';
import { ClearTab } from './components/TabPanels/ClearTab';
import { CaivrsTab } from './components/TabPanels/CaivrsTab';
import { SamTab } from './components/TabPanels/SamTab';
import { OccupationalCheckTab } from './components/TabPanels/OccupationalCheckTab';
import { IndustryCheckTab } from './components/TabPanels/IndustryCheckTab';
import { SbssScoreTab } from './components/TabPanels/SbssScoreTab';
import { OtherSbaLoansTab } from './components/TabPanels/OtherSbaLoansTab';
import { OnlinePresenceTab } from './components/TabPanels/OnlinePresenceTab';
import { CollateralTab } from './components/TabPanels/CollateralTab';
import { AffiliatesTab } from './components/TabPanels/AffiliatesTab';
import { SizeStandardTab } from './components/TabPanels/SizeStandardTab';
import { AltSizeStandardTab } from './components/TabPanels/AltSizeStandardTab';
import { DscrTab } from './components/TabPanels/DscrTab';
import { initializeObjectWithId } from './utils';
import { saveApplication, getDownloadUrl } from './service';
import { OBJECT_TYPES } from './constants';
import { TimestampFooter } from './components/TimestampFooter';

export default function Prescreen({
  application,
  updateApplication,
  addSuccessNotification,
  addErrorNotification,
  addWarningNotification,
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const tabIndex = application?.ActiveTab || 0;

  const SortedObjects = orderBy(
    application?.Objects,
    ['ObjectType', 'DateAdded'],
    ['desc', 'desc']
  );
  const Guarantors = filterItemsByObjectTypes(SortedObjects, [OBJECT_TYPES.GUARANTOR]);
  const Borrowers = filterItemsByObjectTypes(SortedObjects, [OBJECT_TYPES.BORROWER]);
  const Affiliates = filterItemsByObjectTypes(SortedObjects, [OBJECT_TYPES.AFFILIATE]);

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsSaving(true);
    saveApplication(application)
      .then(() => {
        addSuccessNotification('Data saved successfully.');
      })
      .catch((error) => {
        console.error(error);
        addErrorNotification('Failed to save data.');
      })
      .finally(() => setIsSaving(false));
  };

  const handleDownloadPdf = () => {
    setIsGenerating(true);
    getDownloadUrl(application?.id)
      .then((url) => {
        if (url) {
          window.open(url);
        } else {
          addErrorNotification('Failed to get file url.');
        }
      })
      .catch(() => {
        addErrorNotification('Failed to generate file.');
      })
      .finally(() => setIsGenerating(false));
  };

  const updateApplicationByPaths = (attrs) => {
    // attrs = [ { path, value }, { path, value } ]
    const nextApp = produce(application, (draftState) => {
      for (const attr of attrs) {
        const { path, value } = attr;
        set(draftState, path, value);
      }
    });

    updateApplication(nextApp);
  };

  const updateObjectInArray = (arrayPath, itemId, attributePath, value) => {
    const nextApp = produce(application, (draftState) => {
      const array = get(draftState, arrayPath) || [];
      const newArray = array.map((item) => {
        if (item.id === itemId) {
          set(item, attributePath, value);

          return item;
        }

        return item;
      });

      set(draftState, arrayPath, newArray);
    });

    updateApplication(nextApp);
  };

  const addNewItem = (arrayPath, initialObj) => {
    const nextApp = produce(application, (draftState) => {
      const array = get(draftState, arrayPath) || [];
      array.push(initializeObjectWithId(initialObj));

      set(draftState, arrayPath, array);
    });

    updateApplication(nextApp);
  };

  const removeItem = (arrayPath, id) => {
    const nextApp = produce(application, (draftState) => {
      const array = get(draftState, arrayPath) || [];
      const newArray = array.filter((item) => item.id !== id);

      set(draftState, arrayPath, newArray);
    });

    updateApplication(nextApp);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="title-main">
        SBA 7a Prescreen Checklist{' '}
        <button
          className="btn btn-primary ml--lg"
          style={{ minWidth: '125px' }}
          type="submit"
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </button>
        <button
          className="btn btn-sm btn-pdf ml"
          type="button"
          disabled={isGenerating}
          onClick={handleDownloadPdf}
        >
          <MdPictureAsPdf size="20px" />
        </button>
      </h1>

      <Tabs
        className="tabs-container"
        index={application?.ActiveTab || 0}
        onChange={(index) => updateApplicationByPaths([{ path: 'ActiveTab', value: index }])}
      >
        <TabList className="tab-list">
          <Tab>Objects</Tab>
          <Tab>
            Personal Credit{' '}
            {isEveryObjectComplete(Guarantors, 'PersonalCredit') && (
              <MdDone className="icon-done" />
            )}
          </Tab>
          <Tab>
            CLEARs{' '}
            {isEveryObjectComplete([...Guarantors, ...Borrowers], 'Clear') && (
              <MdDone className="icon-done" />
            )}
          </Tab>
          <Tab>
            CAIVRS Search{' '}
            {isEveryObjectComplete([...Guarantors, ...Borrowers], 'Caivrs') && (
              <MdDone className="icon-done" />
            )}
          </Tab>
          <Tab>
            SAM Search{' '}
            {isEveryObjectComplete([...Guarantors, ...Borrowers], 'Sam') && (
              <MdDone className="icon-done" />
            )}
          </Tab>
          <Tab>
            SBSS Score {!!application?.SbssScore?.Timestamp && <MdDone className="icon-done" />}
          </Tab>
          <Tab>DSCR {!!application?.Dscr?.Timestamp && <MdDone className="icon-done" />}</Tab>
          <Tab>
            Business Description{' '}
            {!!application?.BusinessDescription?.Timestamp && <MdDone className="icon-done" />}
          </Tab>
          <Tab>
            Tax Lien(s){' '}
            {isEveryObjectComplete([...Guarantors, ...Borrowers], 'TaxLien') && (
              <MdDone className="icon-done" />
            )}
          </Tab>
          <Tab>
            Use of Funds Breakdown{' '}
            {!!application?.UseOfFunds?.Timestamp && <MdDone className="icon-done" />}
          </Tab>
          <Tab>
            Occupational License Check{' '}
            {isEveryObjectComplete([...Guarantors, ...Borrowers], 'OccupationalCheck') && (
              <MdDone className="icon-done" />
            )}
          </Tab>
          <Tab>
            Industry Check{' '}
            {!!application?.IndustryCheck?.Timestamp && <MdDone className="icon-done" />}
          </Tab>
          <Tab>
            Other SBA Loans{' '}
            {!!application?.OtherSbaLoans?.Timestamp && <MdDone className="icon-done" />}
          </Tab>
          <Tab>
            Online Presence{' '}
            {!!application?.OnlinePresence?.Timestamp && <MdDone className="icon-done" />}
          </Tab>
          <Tab>
            Collateral {!!application?.Collateral?.Timestamp && <MdDone className="icon-done" />}
          </Tab>
          <Tab>
            Affiliates {!!application?.Affiliates?.Timestamp && <MdDone className="icon-done" />}
          </Tab>
          <Tab>
            Size Standard{' '}
            {!!application?.SizeStandard?.Timestamp && <MdDone className="icon-done" />}
          </Tab>
          <Tab>
            Alternative Size Standard{' '}
            {!!application?.AltSizeStandard?.Timestamp && <MdDone className="icon-done" />}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {tabIndex === 0 && (
              <Section className="section-objects">
                <ObjectsTab
                  SortedObjects={SortedObjects}
                  addNewItem={addNewItem}
                  removeItem={removeItem}
                  addWarningNotification={addWarningNotification}
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 1 && (
              <Section>
                <PersonalCreditTab
                  PersonalCredit={application?.PersonalCredit}
                  Guarantors={Guarantors}
                  updateApplicationByPath={(path, value) =>
                    updateApplicationByPaths([{ path, value }])
                  }
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 2 && (
              <Section>
                <ClearTab
                  Clear={application?.Clear}
                  Guarantors={Guarantors}
                  Borrowers={Borrowers}
                  updateApplicationByPath={(path, value) =>
                    updateApplicationByPaths([{ path, value }])
                  }
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 3 && (
              <Section>
                <CaivrsTab
                  Guarantors={Guarantors}
                  Borrowers={Borrowers}
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 4 && (
              <Section>
                <SamTab
                  Guarantors={Guarantors}
                  Borrowers={Borrowers}
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 5 && (
              <Section
                timestamp={application?.SbssScore?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([{ path: 'SbssScore.Timestamp', value: timestamp }])
                }
              >
                <SbssScoreTab
                  SbssScore={application?.SbssScore}
                  updateApplicationByPath={(path, value) =>
                    updateApplicationByPaths([{ path, value }])
                  }
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 6 && (
              <Section
                timestamp={application?.Dscr?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([{ path: 'Dscr.Timestamp', value: timestamp }])
                }
              >
                <DscrTab
                  Dscr={application?.Dscr}
                  addNewItem={addNewItem}
                  removeItem={removeItem}
                  updateApplicationByPath={(path, value) =>
                    updateApplicationByPaths([{ path, value }])
                  }
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 7 && (
              <Section
                timestamp={application?.BusinessDescription?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([
                    { path: 'BusinessDescription.Timestamp', value: timestamp },
                  ])
                }
              >
                <BusinessDescriptionTab
                  BusinessDescription={application?.BusinessDescription}
                  updateApplicationByPath={(path, value) =>
                    updateApplicationByPaths([{ path, value }])
                  }
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 8 && (
              <Section>
                <TaxLienTab
                  Guarantors={Guarantors}
                  Borrowers={Borrowers}
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 9 && (
              <Section
                timestamp={application?.UseOfFunds?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([{ path: 'UseOfFunds.Timestamp', value: timestamp }])
                }
              >
                <UseOfFundsTab
                  UseOfFunds={application?.UseOfFunds}
                  addNewItem={addNewItem}
                  removeItem={removeItem}
                  updateApplicationByPath={(path, value) =>
                    updateApplicationByPaths([{ path, value }])
                  }
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 10 && (
              <Section>
                <OccupationalCheckTab
                  Guarantors={Guarantors}
                  Borrowers={Borrowers}
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 11 && (
              <Section
                timestamp={application?.IndustryCheck?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([{ path: 'IndustryCheck.Timestamp', value: timestamp }])
                }
              >
                <IndustryCheckTab
                  IndustryCheck={application?.IndustryCheck}
                  updateApplicationByPaths={updateApplicationByPaths}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 12 && (
              <Section
                timestamp={application?.OtherSbaLoans?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([{ path: 'OtherSbaLoans.Timestamp', value: timestamp }])
                }
              >
                <OtherSbaLoansTab
                  OtherSbaLoans={application?.OtherSbaLoans}
                  addNewItem={addNewItem}
                  removeItem={removeItem}
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 13 && (
              <Section
                timestamp={application?.OnlinePresence?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([{ path: 'OnlinePresence.Timestamp', value: timestamp }])
                }
              >
                <OnlinePresenceTab
                  OnlinePresence={application?.OnlinePresence}
                  Guarantors={Guarantors}
                  updateApplicationByPath={(path, value) =>
                    updateApplicationByPaths([{ path, value }])
                  }
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 14 && (
              <Section
                timestamp={application?.Collateral?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([{ path: 'Collateral.Timestamp', value: timestamp }])
                }
              >
                <CollateralTab
                  Collateral={application?.Collateral}
                  addNewItem={addNewItem}
                  removeItem={removeItem}
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 15 && (
              <Section
                timestamp={application?.Affiliates?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([{ path: 'Affiliates.Timestamp', value: timestamp }])
                }
              >
                <AffiliatesTab
                  Affiliates={Affiliates}
                  removeItem={removeItem}
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 16 && (
              <Section
                timestamp={application?.SizeStandard?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([{ path: 'SizeStandard.Timestamp', value: timestamp }])
                }
              >
                <SizeStandardTab
                  SizeStandard={application?.SizeStandard}
                  addNewItem={addNewItem}
                  removeItem={removeItem}
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 17 && (
              <Section
                timestamp={application?.AltSizeStandard?.Timestamp}
                onTimestampChange={(timestamp) =>
                  updateApplicationByPaths([
                    { path: 'AltSizeStandard.Timestamp', value: timestamp },
                  ])
                }
              >
                <AltSizeStandardTab
                  AltSizeStandard={application?.AltSizeStandard}
                  addNewItem={addNewItem}
                  removeItem={removeItem}
                  updateObjectInArray={updateObjectInArray}
                />
              </Section>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <div className="divider mt--xl mb--xl"></div>

      <div className="mb">
        <label htmlFor="other-comments">Other comments</label>
        <textarea
          className="display-block w100"
          id="other-comments"
          rows={7}
          value={application?.Comments}
          onChange={(event) =>
            updateApplicationByPaths([{ path: 'Comments', value: event.target.value }])
          }
        ></textarea>
      </div>

      <div className="form-group-inline">
        <label htmlFor="underwriter">Underwriter</label>
        <Input
          className="input-longer"
          id="underwriter"
          type="text"
          value={application?.Underwriter}
          onChange={(event) =>
            updateApplicationByPaths([{ path: 'Underwriter', value: event.target.value }])
          }
        />
      </div>

      <TimestampFooter
        className="timestamp-footer--global"
        timestamp={application?.Timestamp}
        onChange={(timestamp) =>
          updateApplicationByPaths([{ path: 'Timestamp', value: timestamp }])
        }
      />
    </form>
  );
}

function filterItemsByObjectTypes(list, objectTypes) {
  return list?.filter(({ ObjectType }) => objectTypes.includes(ObjectType)) || [];
}

function isEveryObjectComplete(objects, sectionName) {
  if (!objects?.length) {
    return false;
  }

  return objects?.every((object) => get(object, `${sectionName}.Timestamp`));
}
