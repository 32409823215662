export const OBJECT_TYPES = {
  GUARANTOR: 'guarantor',
  BORROWER: 'borrower',
  AFFILIATE: 'affiliate',
};

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};
