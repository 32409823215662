import React from 'react';
import { Fieldset } from '../Fieldset';
import { Input } from '../Input';

export function OnlinePresenceTab({
  OnlinePresence,
  Guarantors,
  updateApplicationByPath,
  updateObjectInArray,
}) {
  return (
    <>
      <div className="mb--sm form-group-inline">
        <label htmlFor="online-presence-website">Website</label>
        <Input
          className="input-longer"
          id="online-presence-website"
          type="url"
          placeholder="https://example.com"
          value={OnlinePresence?.Website}
          onChange={(event) =>
            updateApplicationByPath('OnlinePresence.Website', event.target.value)
          }
        />
      </div>

      <div className="mb--sm form-group-inline">
        <label htmlFor="online-presence-facebook">Facebook</label>
        <Input
          className="input-longer"
          id="online-presence-facebook"
          type="url"
          placeholder="https://facebook.com"
          value={OnlinePresence?.Facebook}
          onChange={(event) =>
            updateApplicationByPath('OnlinePresence.Facebook', event.target.value)
          }
        />
      </div>

      <div className="mb--sm form-group-inline">
        <label htmlFor="online-presence-yelp">Yelp</label>
        <Input
          className="input-longer"
          id="online-presence-yelp"
          type="url"
          placeholder="http://yelp.com"
          value={OnlinePresence?.Yelp}
          onChange={(event) => updateApplicationByPath('OnlinePresence.Yelp', event.target.value)}
        />
      </div>

      <div className="mb--sm form-group-inline">
        <label htmlFor="online-presence-twitter">Twitter</label>
        <Input
          className="input-longer"
          id="online-presence-twitter"
          type="url"
          placeholder="http://twitter.com"
          value={OnlinePresence?.Twitter}
          onChange={(event) =>
            updateApplicationByPath('OnlinePresence.Twitter', event.target.value)
          }
        />
      </div>

      <div className="mb--sm form-group-inline">
        <label htmlFor="online-presence-instagram">Instagram</label>
        <Input
          className="input-longer"
          id="online-presence-instagram"
          type="url"
          placeholder="http://instagram.com"
          value={OnlinePresence?.Instagram}
          onChange={(event) =>
            updateApplicationByPath('OnlinePresence.Instagram', event.target.value)
          }
        />
      </div>

      <div className="mb--sm form-group-inline">
        <label htmlFor="online-presence-linkedin">LinkedIn</label>
        <Input
          className="input-longer"
          id="online-presence-linkedin"
          type="url"
          placeholder="http://linkedin.com"
          value={OnlinePresence?.LinkedIn}
          onChange={(event) =>
            updateApplicationByPath('OnlinePresence.LinkedIn', event.target.value)
          }
        />
      </div>

      <div className="mb form-group-inline">
        <label htmlFor="online-presence-bbb">BBB</label>
        <Input
          className="input-longer"
          id="online-presence-bbb"
          type="url"
          placeholder="https://www.bbb.org"
          value={OnlinePresence?.BBB}
          onChange={(event) => updateApplicationByPath('OnlinePresence.BBB', event.target.value)}
        />
      </div>

      {Guarantors.map((object) => {
        const { id, ObjectType, Name } = object;

        return (
          <Fieldset className="mb" key={id} id={id} legend={ObjectType}>
            <div className="mb--sm form-group-inline">
              <label htmlFor={`name-${id}`}>Name</label>
              <Input
                className="input-longer borderless backgroundless font-semibold"
                id={`name-${id}`}
                readOnly
                value={Name}
              />
            </div>

            <div className="form-group-inline">
              <label htmlFor="online-presence-linkedin">LinkedIn</label>
              <Input
                className="input-longer"
                id="online-presence-linkedin"
                type="url"
                placeholder="http://linkedin.com"
                value={object?.OnlinePresence?.LinkedIn}
                onChange={(event) =>
                  updateObjectInArray('Objects', id, 'OnlinePresence.LinkedIn', event.target.value)
                }
              />
            </div>
          </Fieldset>
        );
      })}
    </>
  );
}
