import React, { useEffect } from 'react';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import { NOTIFICATION_TYPE } from '../../constants';
import './styles.css';

const NOTIFICATION_AUTO_CLOSING_TIMEOUT = 3 * 1000; // only for success notifications

export function Notification({ notification, onClose }) {
  const { message, type = NOTIFICATION_TYPE.SUCCESS } = notification;
  const className = classNames('notification', `notification-${type}`);
  const autoClosing = type !== NOTIFICATION_TYPE.ERROR;

  useEffect(() => {
    let timer;

    if (autoClosing) {
      timer = setTimeout(() => onClose(), NOTIFICATION_AUTO_CLOSING_TIMEOUT);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [autoClosing, onClose]);

  return (
    <div className={className}>
      {message}

      <button className="notification__close" type="button" onClick={onClose}>
        <MdClose size="24px" />
      </button>
    </div>
  );
}
