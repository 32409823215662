import React from 'react';
import classNames from 'classnames';
import { getTimestamp, timestampToLocalDate } from '../../utils';
import './styles.css';

export function TimestampFooter({ className, timestamp, onChange }) {
  return (
    <div className={classNames('timestamp-footer mt--lg', className)}>
      <button
        className="btn btn-primary btn-sm"
        type="button"
        onClick={() => onChange(getTimestamp())}
      >
        {timestamp ? 'Refresh timestamp' : 'Add timestamp'}
      </button>

      <div>
        {timestamp && (
          <>
            <span>Date &amp; Time Verified</span>&nbsp;
            <strong>{timestampToLocalDate(timestamp)}</strong>
          </>
        )}
      </div>
    </div>
  );
}
