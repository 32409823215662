import React from 'react';
import { hasElements } from '../../utils';
import { Input } from '../Input';
import { Fieldset } from '../Fieldset';

export function PersonalCreditTab({
  PersonalCredit,
  Guarantors,
  updateObjectInArray,
  updateApplicationByPath,
}) {
  return (
    <>
      <div className="mb--lg">
        {!hasElements(Guarantors) && (
          <div className="text-center">
            <strong>No guarantors/borrowers defined</strong>
          </div>
        )}

        {Guarantors.map((object) => {
          const { id, ObjectType, Name } = object;

          return (
            <Fieldset
              className="mb"
              key={id}
              id={id}
              legend={ObjectType}
              timestamp={object?.PersonalCredit?.Timestamp}
              onTimestampChange={(timestamp) =>
                updateObjectInArray('Objects', id, 'PersonalCredit.Timestamp', timestamp)
              }
            >
              <div className="mb--sm form-group-inline">
                <label htmlFor={`name-${id}`}>Name</label>
                <Input
                  className="input-longer borderless backgroundless font-semibold"
                  id={`name-${id}`}
                  readOnly
                  value={Name}
                />
              </div>

              <div className="mb--sm form-group-inline">
                <label htmlFor={`score-${id}`}>Score</label>
                <Input
                  id={`score-${id}`}
                  type="number"
                  min="0"
                  value={object?.PersonalCredit?.Score}
                  onChange={(event) =>
                    updateObjectInArray('Objects', id, 'PersonalCredit.Score', event.target.value)
                  }
                />
              </div>

              <div className="form-group-inline">
                <label htmlFor={`date-report-obtained-${id}`}>Date Report Obtained</label>
                <Input
                  id={`date-report-obtained-${id}`}
                  type="date"
                  value={object?.PersonalCredit?.DateReportObtained}
                  onChange={(event) =>
                    updateObjectInArray(
                      'Objects',
                      id,
                      'PersonalCredit.DateReportObtained',
                      event.target.value
                    )
                  }
                />
              </div>
            </Fieldset>
          );
        })}
      </div>

      <label className="display-block mb--sm">
        <Input
          type="checkbox"
          checked={!!PersonalCredit?.ScoreGte640}
          onChange={(event) =>
            updateApplicationByPath('PersonalCredit.ScoreGte640', event.target.checked)
          }
        />{' '}
        Personal Credit Score {'>='} 640 for all guarantors
      </label>
      <label className="display-block mb--sm">
        <Input
          type="checkbox"
          checked={!!PersonalCredit?.NoNotilityCredit}
          onChange={(event) =>
            updateApplicationByPath('PersonalCredit.NoNotilityCredit', event.target.checked)
          }
        />{' '}
        No Non-medical or Non-utility credit charge-off {'>='} 20000 in the past 2 years for all
        borrowers
      </label>
      <label className="display-block mb--sm">
        <Input
          type="checkbox"
          checked={!!PersonalCredit?.Has2PastDueTradelines3Mos}
          onChange={(event) =>
            updateApplicationByPath(
              'PersonalCredit.Has2PastDueTradelines3Mos',
              event.target.checked
            )
          }
        />{' '}
        No guarantor has 2 or more 30+ past due tradelines in last 3 mos?
      </label>
      <label className="display-block mb--sm">
        <Input
          type="checkbox"
          checked={!!PersonalCredit?.Has2PastDueTradelinesLastYer}
          onChange={(event) =>
            updateApplicationByPath(
              'PersonalCredit.Has2PastDueTradelinesLastYer',
              event.target.checked
            )
          }
        />{' '}
        No guarantor has 2 or more 60+ past due tradelines in last year?
      </label>
    </>
  );
}
