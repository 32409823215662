import React from 'react';
import { Input } from '../Input';

export function SbssScoreTab({ SbssScore, updateApplicationByPath }) {
  return (
    <>
      <div className="mb--sm form-group-inline">
        <label htmlFor="sbss-score">SBSS Score</label>
        <Input
          id="sbss-score"
          type="number"
          min="0"
          max="300"
          step="0.1"
          value={SbssScore?.Score}
          onChange={(event) => updateApplicationByPath('SbssScore.Score', event.target.value)}
        />
      </div>

      <div className="form-group-inline">
        <label htmlFor="sbss-score-date-obtained">Date Score Obtained</label>
        <Input
          id="sbss-score-date-obtained"
          type="date"
          value={SbssScore?.DateObtained}
          onChange={(event) =>
            updateApplicationByPath('SbssScore.DateObtained', event.target.value)
          }
        />
      </div>
    </>
  );
}
